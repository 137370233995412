<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div
            :class="{'is-modal-open': isModalOpen}"
            class="main-video">
          <div class="video-frame-container">
            <close-button
                @clicked="closeModal()"
            />
            <div class="playing-video-frame">
                <div class="video"
                     v-if="videoPlaying"
                     :class="videoScript"
                     :key="selectedVideoIndex"
                >&nbsp;</div>
              <cover
                  @clicked="addScript()"
                  v-if="!videoPlaying"
                  :video="videoListFields.videos[selectedVideoIndex]"
              />
            </div>
          </div>
          <div class="rebrand-content">
            <h4>{{ videoListFields.videos[selectedVideoIndex].title }}</h4>
            <div v-html="videoListFields.videos[selectedVideoIndex].text"></div>
          </div>
        </div>
      </div>
      <div class="col-md-4 position-relative side-column">
        <div class="side-videos">
          <div
              v-for="(video, index) in videoListFields.videos"
              class="video-frame-container">
            <div
                :class="{'selected': (index === selectedVideoIndex)}"
                class="playing-video-frame">
              <cover
                  @clicked="updateSelectedIndex(index)"
                  :video="video"
              />
            </div>
            <div
                :class="{'selected': (index === selectedVideoIndex)}"
                class="rebrand-content desktop">
              <h5>{{video.title}}</h5>
            </div>
            <div
                class="rebrand-content mobile">
              <h4>{{ video.title }}</h4>
              <div v-html="video.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import cover from './Cover.vue'
import closeButton from './CloseButton.vue'

export default {
  components: {
    cover,
    closeButton
  },
  props: ['data'],
  data () {
    return {
      videoScript: '',
      selectedVideoIndex: 0,
      videoPlaying: false,
      isModalOpen: false
    }
  },
  methods: {
    addScript () {
      let videoCode = this.videoListFields.videos[this.selectedVideoIndex].video_code
      this.videoPlaying = true
      this.videoScript = "wistia_embed wistia_async_" + videoCode

      setTimeout(function () {
        wistiaEmbeds[0].play()
      },1000)
    },
    closeModal () {
      this.isModalOpen = false
    },
    updateSelectedIndex (index) {
      this.isModalOpen = true
      this.selectedVideoIndex = index
      this.videoPlaying = false
      this.addScript()
    }
  },

}
</script>
