<template>
  <button
      class="cover"
      @click="clicked"
      :style="{background: 'url(' + video.cover_image.url + ') no-repeat center/cover'}">
    <div class="play-button">
      <div class="play-icon"></div>
    </div>
  </button>
</template>
<script>
export default {
    props: ['video'],
    name: 'cover',
    methods: {
      clicked ()  {
        this.$emit('clicked')
      }
    }
  }
</script>
