var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8" }, [
        _c(
          "div",
          {
            staticClass: "main-video",
            class: { "is-modal-open": _vm.isModalOpen },
          },
          [
            _c(
              "div",
              { staticClass: "video-frame-container" },
              [
                _c("close-button", {
                  on: {
                    clicked: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "playing-video-frame" },
                  [
                    _vm.videoPlaying
                      ? _c(
                          "div",
                          {
                            key: _vm.selectedVideoIndex,
                            staticClass: "video",
                            class: _vm.videoScript,
                          },
                          [_vm._v(" ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.videoPlaying
                      ? _c("cover", {
                          attrs: {
                            video:
                              _vm.videoListFields.videos[
                                _vm.selectedVideoIndex
                              ],
                          },
                          on: {
                            clicked: function ($event) {
                              return _vm.addScript()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "rebrand-content" }, [
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.videoListFields.videos[_vm.selectedVideoIndex].title
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.videoListFields.videos[_vm.selectedVideoIndex].text
                  ),
                },
              }),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4 position-relative side-column" }, [
        _c(
          "div",
          { staticClass: "side-videos" },
          _vm._l(_vm.videoListFields.videos, function (video, index) {
            return _c("div", { staticClass: "video-frame-container" }, [
              _c(
                "div",
                {
                  staticClass: "playing-video-frame",
                  class: { selected: index === _vm.selectedVideoIndex },
                },
                [
                  _c("cover", {
                    attrs: { video: video },
                    on: {
                      clicked: function ($event) {
                        return _vm.updateSelectedIndex(index)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "rebrand-content desktop",
                  class: { selected: index === _vm.selectedVideoIndex },
                },
                [_c("h5", [_vm._v(_vm._s(video.title))])]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "rebrand-content mobile" }, [
                _c("h4", [_vm._v(_vm._s(video.title))]),
                _vm._v(" "),
                _c("div", { domProps: { innerHTML: _vm._s(video.text) } }),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }