var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "close rebrand-close-button mobile",
      attrs: { type: "button", "aria-label": "Close" },
      on: { click: _vm.clicked },
    },
    [
      _c("span", { attrs: { "aria-hidden": "true" } }, [
        _c(
          "svg",
          {
            attrs: {
              width: "48",
              height: "40",
              viewBox: "0 0 48 40",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M38 6L24 20M24 20L10 34M24 20L10 6M24 20L38 34",
                stroke: "white",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }