<template>
  <button
      type="button"
      @click="clicked"
      class="close rebrand-close-button mobile"
      aria-label="Close">
        <span aria-hidden="true">
            <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M38 6L24 20M24 20L10 34M24 20L10 6M24 20L38 34" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
  </button>
</template>
<script>
export default {
  props: ['video'],
  name: 'close-button',
  methods: {
    clicked ()  {
      this.$emit('clicked')
    }
  }
}
</script>
