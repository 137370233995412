var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "cover",
      style: {
        background:
          "url(" + _vm.video.cover_image.url + ") no-repeat center/cover",
      },
      on: { click: _vm.clicked },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "play-button" }, [
      _c("div", { staticClass: "play-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }