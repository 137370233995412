import Vue from 'vue'
import App from './VideoListing.vue'

// polyfill for Object.entries
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}
const videoListings = document.getElementsByClassName("video-listing-container");
for (let i = 0; i < videoListings.length; i++) {
  Vue.prototype.wistiaEmbeds = window.wistiaEmbeds
  Vue.prototype.videoListFields = window[videoListings[i].dataset.target]
  Vue.prototype.windowWidth = window.innerWidth
  let id = 'videoListing' + i;
  videoListings[i].id = id;
  new Vue({
    render: h => h(App),
  }).$mount('#' + id)
}


